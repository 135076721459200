import Image1 from '../../assets/turntable.png';
import Image2 from '../../assets/travel.png';
import Image3 from '../../assets/jazz-cafe.png';
import Image4 from '../../assets/bread.jpg';
import Image5 from '../../assets/bike.png';

export const SliderData = [
  {
    image: Image1,
    alt: 'turntable'
  },
  {
    image: Image2,
    alt: 'travel'
  },

  {
    image: Image3,
    alt: 'live-music'
  },

  {
    image: Image4,
    alt: 'bread'
  },

  {
    image: Image5,
    alt: 'bike'
  }
];
